<template>
	<!-- Profile Information Card -->
	<a-card :bordered="false" class="header-solid h-full card-profile-information" :bodyStyle="{paddingTop: 0, paddingBottom: '16px' }" :headStyle="{paddingRight: 0,}">
		<template #title>
			<h6 class="font-semibold m-0">Ganti Password</h6>
		</template>

		<div v-if="alert.msg" class="mb-2">
			<a-alert :message="alert.msg" :type="alert.type" closable></a-alert>
		</div>

		<div class="mb-3">
      		<a-form
				id="components-form-demo-normal-login"
				:form="form"
				@submit="handleSubmit"
				:hideRequiredMark="true"
			>
				<a-form-item label="Password lama" style="margin-bottom: 5px;">
					<a-input 
					v-decorator="[
					'old_password',
					{ rules: [{ required: true, message: 'Masukkan password lama anda!' }] },
					]" type="password" placeholder="Password lama" />
				</a-form-item>
				<a-form-item label="Password baru">
					<a-input 
					v-decorator="[
					'new_password',
					{ rules: [{ required: true, message: 'Masukkan password baru anda!' }] },
					]" type="password" placeholder="Password baru" />
				</a-form-item>

				<a-form-item :wrapper-col="{ span: 8, offset: 0 }">
					<a-button type="primary" block html-type="submit">
						UBAH PASSWORD
					</a-button>
				</a-form-item>
				<br/>
			</a-form>
		</div>
	</a-card>
	<!-- / Profile Information Card -->

</template>

<script>

	import { processMessage } from '../../helpers';

	export default ({
		data() {
			return {
				user: {},
				alert: {
					type: '',
					msg: ''
				},
				modalEdit: false
			}
		},
		beforeCreate() {
			this.form = this.$form.createForm(this, { name: 'create_data' });
		},
		methods: {
			handleSubmit(e){
				e.preventDefault();
				this.form.validateFields((err, values) => {
					if ( !err ) {
						this.$http.post(process.env.VUE_APP_API + '/user/account', {
							old_password: values.old_password,
							new_password: values.new_password,
							_method: 'PUT'
						})
						.then((res) => {
							this.alert = {
								type: 'success',
								msg: 'Password berhasil di ubah'
							}
							this.modalEdit = false

							this.form.resetFields()
						})
						.catch(err => {
							this.alert = processMessage(err.response)
						})
					}
				});
				
			}
		}
	})

</script>