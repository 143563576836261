<!-- 
	This is the user profile page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
	<div>

		<!-- Header Background Image -->
		<div class="profile-nav-bg" style="background-image: url('images/bg-profile.jpg')"></div>
		<!-- / Header Background Image -->

		<!-- User Profile Card -->
		<!-- <a-card :bordered="false" class="card-profile-head" :bodyStyle="{padding: 0,}">
			<template #title>
				<a-row type="flex" align="middle">
					<a-col :span="24" :md="12" class="col-info">
						<a-avatar :size="74" shape="square" src="images/face-1.jpg" />
						<div class="avatar-info">
							<h4 class="font-semibold m-0">{{$store.state.user.fullname}}</h4>
							<p>{{$store.state.user.role}}</p>
						</div>
					</a-col> -->
					<!-- <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
						<a-radio-group v-model="profileHeaderBtns" size="small">
							<a-radio-button value="overview">OVERVIEW</a-radio-button>
							<a-radio-button value="teams">TEAMS</a-radio-button>
							<a-radio-button value="projects">PROJECTS</a-radio-button>
						</a-radio-group>
					</a-col> -->
				<!-- /a-row>
			</template>
		</a-card> -->
		<!-- User Profile Card -->

		<a-row type="flex" :gutter="24" class="mt-3">

			<!-- /Platform Settings Column -->
			<!-- <a-col :span="24" :md="8" class="mb-24"> -->

				<!-- Platform Settings Card -->
				<!-- <CardPlatformSettings></CardPlatformSettings> -->
				<!-- / Platform Settings Card -->

			<!-- </a-col> -->
			<!-- / Platform Settings Column -->

			<!-- Profile Information Column -->
			<a-col :span="24" :md="12" class="mb-24">

				<!-- Profile Information Card -->
				<CardProfileInformation></CardProfileInformation>
				<!-- / Profile Information Card -->

			</a-col>
			<!-- / Profile Information Column -->
			
			<!-- Conversations Column -->
			<a-col :span="24" :md="12" class="mb-24">
			
				<!-- Conversations Card -->
				<CardChangePassword></CardChangePassword>
				<!-- / Conversations Card -->

			</a-col>
			<!-- / Conversations Column -->

		</a-row>

	</div>
</template>

<script>

	import CardChangePassword from "../components/Cards/CardChangePassword"
	import CardProfileInformation from "../components/Cards/CardProfileInformation"

	export default ({
		components: {
			CardChangePassword,
			CardProfileInformation,
		},
		data() {
			return {
				// Active button for the "User Profile" card's radio button group.
				profileHeaderBtns: 'overview',
			}
		},
	})

</script>

<style lang="scss">
</style>