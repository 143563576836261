<template>
	<!-- Profile Information Card -->
	<a-card :bordered="false" class="header-solid h-full card-profile-information" :bodyStyle="{paddingTop: 0, paddingBottom: '16px' }" :headStyle="{paddingRight: 0,}">
		<template #title>
			<h6 class="font-semibold m-0">Informasi Profil</h6>
		</template>

		<loading :active.sync="isLoading" :is-full-page="true"></loading>
		<div v-if="alert.msg" class="mb-2">
			<a-alert :message="alert.msg" :type="alert.type" closable></a-alert>
		</div>

		<a-modal v-model="modalEdit" title="Ubah Data" footer="">
			<div class="mb-3">
	      		<a-form
	      			:label-col="{ span: 6 }" 
	      			:wrapper-col="{ span: 18 }" 
					id="components-form-demo-normal-login"
					:form="form"
					@submit="handleSubmit"
					:hideRequiredMark="true"
				>
					<a-form-item label="Username" style="margin-bottom: 5px;">
						<a-input 
						v-decorator="[
						'username',
						{ rules: [{ required: true, message: 'Masukkan username anda!' }], initialValue: user.username },
						]" placeholder="Username" />
					</a-form-item>
					<a-form-item label="Nama lengkap" style="margin-bottom: 5px;">
						<a-input 
						v-decorator="[
						'fullname',
						{ rules: [{ required: true, message: 'Masukkan nama lengkap anda!' }], initialValue: user.fullname },
						]" placeholder="Nama lengkap" />
					</a-form-item>
					<a-form-item label="Email" style="margin-bottom: 5px;">
						<a-input 
						v-decorator="[
						'email',
						{ rules: [{ required: false, type: 'email', message: 'Masukkan email anda!' }], initialValue: user.email },
						]" placeholder="Email" />
					</a-form-item>
					<a-form-item label="Lokasi" style="margin-bottom: 5px;">
						<a-input 
						v-decorator="[
						'location',
						{ rules: [{ required: false, message: 'Masukkan lokasi anda!' }], initialValue: user.location },
						]" placeholder="Lokasi" />
					</a-form-item>

					<a-form-item label="Twitter" style="margin-bottom: 5px;">
						<a-input 
						v-decorator="[
						'twitter',
						{ rules: [{ required: false, message: 'Masukkan alamat twitter anda!' }], initialValue: user.twitter },
						]" placeholder="Alamat twitter" />
					</a-form-item>

					<a-form-item label="Facebook" style="margin-bottom: 5px;">
						<a-input 
						v-decorator="[
						'facebook',
						{ rules: [{ required: false, message: 'Masukkan alamat facebook anda!' }], initialValue: user.facebook },
						]" placeholder="Alamat facebook" />
					</a-form-item>

					<a-form-item label="Instagram">
						<a-input 
						v-decorator="[
						'instagram',
						{ rules: [{ required: false, message: 'Masukkan alamat instagram anda!' }], initialValue: user.instagram },
						]" placeholder="Alamat instagram" />
					</a-form-item>

					<a-form-item :wrapper-col="{ span: 6, offset: 6 }">
						<a-button type="primary" block html-type="submit">
							UBAH DATA
						</a-button>
					</a-form-item>
					<br/>
				</a-form>
			</div>
	    </a-modal>

		<a-button type="link" slot="extra" @click="modalEdit = true">
			<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path class="fill-muted" d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z" fill="#111827"/>
				<path class="fill-muted" d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z" fill="#111827"/>
			</svg>
		</a-button>
		<p class="text-dark">
			Di bawah ini adalah tabel dengan informasi user, termasuk alamat email dan nama user, untuk referensi dan komunikasi yang mudah. Untuk mengubah informasi dibawah, klik tombol edit di ujung atas kanan.
		</p>
		<hr class="my-25">
		<a-descriptions :title="user.role" :column="1">
			<a-descriptions-item label="Username">
				{{user.username}}
			</a-descriptions-item>
			<a-descriptions-item label="Nama lengkap">
				{{user.fullname}}
			</a-descriptions-item>
			<a-descriptions-item label="Status">
				<a-alert message="Active" type="success" show-icon v-if="user.status == 'Active'" />
				<a-alert :message="user.status" type="success" show-icon v-else />
			</a-descriptions-item>
			<a-descriptions-item label="Email">
				{{user.email ? user.email : 'Tidak tersedia'}}
			</a-descriptions-item>
			<a-descriptions-item label="Lokasi">
				{{user.location ? user.location : 'Tidak tersedia'}}
			</a-descriptions-item>
			<a-descriptions-item label="Social" v-if="user.twitter || user.facebook || user.instagram">
				<a target="_blank" :href="user.twitter" class="mx-5 px-5" v-if="user.twitter">
					<a-icon type="twitter" style="color: #3EA1EC;" />
				</a>
				<a target="_blank" :href="user.facebook" class="mx-5 px-5" v-if="user.facebook">
					<a-icon type="facebook" style="color: #344E86" />
				</a>
				<a target="_blank" :href="user.facebook" class="mx-5 px-5" v-if="user.instagram">
					<a-icon type="instagram" style="color: #E1306C" />
				</a>
			</a-descriptions-item>
		</a-descriptions>
	</a-card>
	<!-- / Profile Information Card -->

</template>

<script>

	import { processMessage } from '../../helpers';
	import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';

	export default ({
		data() {
			return {
				user: {},
				alert: {
					type: '',
					msg: ''
				},
				modalEdit: false,
				isLoading: false,
			}
		},
		mounted(){
			this.retrieveProfile()
		},
		components: { Loading },
		beforeCreate() {
			this.form = this.$form.createForm(this, { name: 'create_data' });
		},
		methods: {
			retrieveProfile(){
				this.isLoading = true
				this.$http.get(process.env.VUE_APP_API + '/user/account')
				.then((res) => {
					let data = res.data.data

					this.user = data
					console.log(this.user)
				})
				.catch(err => {
					this.alert = processMessage(err.response)
				})
				.finally(() => {
					this.isLoading = false
				})
			},
			handleSubmit(e){
				e.preventDefault();
				this.form.validateFields((err, values) => {
					if ( !err ) {
						this.isLoading = true
						this.$http.post(process.env.VUE_APP_API + '/user/account', {
							username: values.username,
							fullname: values.fullname,
							email: values.email,
							location: values.location,
							twitter: values.twitter,
							facebook: values.facebook,
							instagram: values.instagram,
							_method: 'PUT'
						})
						.then((res) => {
							this.alert = processMessage(res)
							this.modalEdit = false
							this.retrieveProfile()
						})
						.catch(err => {
							this.alert = processMessage(err.response)
						})
						.finally(() => {
							this.isLoading = false
						})
					}
				});
				
			}
		}
	})

</script>